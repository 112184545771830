import * as Dom from "./dom";
import ProgressBar from "progressbar.js";
import Line from "progressbar.js/line";

const progressColor = "#3da2f5";
let line: Line;
let isPaused = false;

export function setHeight(height: number) {
  Dom.progress.style.height = `${height}px`;
}

export function animate(duration: number) {
  if (line) line.destroy();

  line = new ProgressBar.Line(Dom.progress, {
    color: progressColor,
    svgStyle: {
      width: "100%",
      height: "100%"
    }
  });

  line.animate(1, {
    duration
  });
}

export function pause() {
  line.pause();

  isPaused = true;
}

export function resume() {
  if (!isPaused) return;
  isPaused = false;

  line.resume();
}

export function stop() {
  line.stop();
  line.destroy();
  line = null;
}
