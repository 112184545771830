import * as Display from "./display";
import * as Dom from "./dom";
import * as Controls from "./controls";
import * as Settings from "./settings";
import * as Progress from "./progress";
import { sleep } from "./extensions";

export let words: string[];
export let inLimbo: boolean;
export let isPlaying: boolean;

export async function start() {
  Settings.ensureLoaded();

  if (Dom.init.textarea.value == "") {
    Display.addError("Chybí slova pro zobrazení");
    return;
  }

  words = Dom.init.textarea.value.split("\n");

  await Display.fadeOutInit();

  Display.addLimbo();
  Display.setLimboHeading("Čeká se na spuštění hry");
  inLimbo = true;

  Controls.enablePlayButton();
}

export async function playCountdown() {
  Controls.disableAll();
  Display.removeLimboImage();

  await playCountdownInterval();

  Display.removeLimbo();
  Display.addScene();

  Controls.handleGameStart();

  await playRounds();
}

export async function playCountdownInterval() {
  let seconds = Settings.instance.get("countdown-duration") as number;
  let wordHeight = Settings.instance.get("word-height") as number;

  Dom.limbo.heading.style.fontSize = `${wordHeight}px`;

  Display.setLimboHeading(seconds.toString());

  let intervalId = setInterval(async () => {
    seconds--;
    if (seconds == 0) clearInterval(intervalId);

    Display.setLimboHeading(seconds.toString());
  }, 1000);

  let promise = new Promise((resolve) => setTimeout(resolve, seconds * 1000));

  await promise;
  clearInterval(intervalId);
  Dom.limbo.heading.style.fontSize = null;
  inLimbo = false;
}

export async function playRounds() {
  isPlaying = true;
  Display.addProgress();

  for (let word of words) {
    let duration = Settings.instance.get("shot-duration") as number;
    let height = Settings.instance.get("progress-bar-height") as number;
    Progress.setHeight(height);

    await playRound(word);

    setTimeout(() => {
      Progress.animate(duration * 1000);
    });

    await sleep(duration * 1000);
    if (!isPlaying) return;
  }

  Display.removeProgress();
  Display.removeScene();

  inLimbo = true;
  Display.addLimbo();
  Display.removeLimboImage();

  Display.setLimboHeading("Hra skončila");

  Controls.handleGameEnd();
  isPlaying = false;
}

export async function playRound(word: string) {
  Dom.scene.paragraph.textContent = word;
}

export async function stop() {
  inLimbo = false;
  isPlaying = false;
}

export async function restart() {
  await start();

  Controls.disableAll();
  Controls.enablePlayButton();
}

export async function exit() {
  await stop();

  Progress.stop();

  Display.removeLimbo();
  Display.removeScene();
  Display.addInit();

  Controls.disableAll();
}
