import * as Dom from "./dom";

const basic = new Map<string, string | number>([
  ["font-family", "Verdana"],
  ["min-word-length", 8],
  ["shot-duration", 10],
  ["countdown-duration", 3],
  ["word-height", 200],
  ["progress-bar-height", 30]
]);

export let instance: Map<string, string | number>;
export let timeoutId: number = null;
export let savingDelay = 250;

export function ensureLoaded() {
  if (instance == null) load();
}

export function load() {
  let json = localStorage.getItem("SETTINGS");

  if (json) instance = new Map<string, string | number>(JSON.parse(json));
  else {
    instance = basic;
    save();
  }
}

export function save() {
  let json = JSON.stringify(Array.from(instance.entries()));

  localStorage.setItem("SETTINGS", json);

  apply();
}

export function remove() {
  localStorage.removeItem("SETTINGS");
}

export function scheduleSaving() {
  if (timeoutId != null) clearTimeout(timeoutId);
  timeoutId = setTimeout(save, savingDelay);
}

export function apply() {
  ensureLoaded();

  Dom.scene.paragraph.style.fontSize = `${instance.get("word-height")}px`;
}
