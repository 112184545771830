import * as Dom from "./dom";
import * as Generate from "./generate";
import * as Display from "./display";
import * as Settings from "./settings";
import * as Font from "./font";
import * as Game from "./game";
import * as Controls from "./controls";

Dom.controls.play.onclick = async () => {
  if (Dom.controls.play.dataset.disabled == "true") return;

  if (Game.inLimbo) {
    Controls.disablePlayButton();
    await Game.playCountdown();
  }
};

Dom.controls.restart.onclick = async () => {
  if (Dom.controls.restart.dataset.disabled == "true") return;

  if (!Game.isPlaying) await Game.restart();
};

Dom.controls.exit.onclick = async () => {
  if (Dom.controls.exit.dataset.disabled == "true") return;

  if (Game.isPlaying || Game.inLimbo) {
    Controls.disableExitButton();
    await Game.exit();
  }
};

Dom.init.button.onclick = async () => {
  await Game.start();
};

Dom.controls.generator.onclick = async () => {
  Display.addGenerator();
};

Dom.dialogs.generator.button.onclick = async () => {
  let words = Dom.dialogs.generator.words.valueAsNumber;
  let phoneNumbers = Dom.dialogs.generator.phoneNumbers.valueAsNumber;
  let phoneNumbersNoCode = Dom.dialogs.generator.phoneNumbersNoCode.valueAsNumber;

  let result = "";
  if (words > 0) result += (result.length == 0 ? "" : "\n") + Generate.words(words).join("\n");
  if (phoneNumbers > 0) result += (result.length == 0 ? "" : "\n") + Generate.phoneNumbers(true, phoneNumbers).join("\n");
  if (phoneNumbersNoCode > 0) result += (result.length == 0 ? "" : "\n") + Generate.phoneNumbers(false, phoneNumbersNoCode).join("\n");

  Dom.dialogs.generator.textarea.value = result;
};

Dom.dialogs.generator.close.onclick = async () => {
  Display.removeGenerator();
};

Dom.controls.settings.onclick = async () => {
  Display.addSettings();
};

Dom.dialogs.settings.close.onclick = async () => {
  Display.removeSettings();
};

Dom.dialogs.settings.form.onsubmit = async (event: SubmitEvent) => {
  event.preventDefault();

  let fontFamily = Dom.dialogs.settings.inputs.get("font-family").value;
  if (!fontFamily || fontFamily == "") {
    Display.addError("Chybí název fontu");
    return;
  }

  Font.process(fontFamily);

  console.log("Setting font", fontFamily);
  Font.set(fontFamily);

  Settings.ensureLoaded();
  Settings.instance.set("font-family", fontFamily);
  Settings.save();
};

Array.from(Dom.dialogs.settings.inputs).forEach(([name, input]) => {
  let type = input.getAttribute("type");

  input.onchange = async () => {
    Settings.ensureLoaded();
    Settings.instance.set(name, type == "text" ? input.value : input.valueAsNumber);
    Settings.scheduleSaving();
  };
});

/*
Dom.dialogs.settings.inputs.get("font").oninput = async () => {
  let input = Dom.dialogs.settings.inputs.get("font");

  console.log("Font Input", input.value);
};
*/

Dom.dialogs.error.close.onclick = async () => {
  Display.removeError();
};
