export const progress = document.querySelector(".progress") as HTMLDivElement;
export const limbo = {
  self: document.querySelector(".limbo") as HTMLDivElement,
  img: document.querySelector(".limbo img") as HTMLImageElement,
  heading: document.querySelector(".limbo h3") as HTMLHeadingElement
};
export const game = document.querySelector(".game") as HTMLDivElement;
export const init = {
  self: document.querySelector(".init") as HTMLDivElement,
  textarea: document.querySelector(".init > textarea") as HTMLTextAreaElement,
  button: document.querySelector(".init > button") as HTMLButtonElement
};
export const scene = {
  self: document.querySelector(".scene") as HTMLDivElement,
  paragraph: document.querySelector(".scene > p") as HTMLParagraphElement
};

export const controls = {
  play: document.querySelector(".control.play") as HTMLDivElement,
  restart: document.querySelector(".control.restart") as HTMLDivElement,
  exit: document.querySelector(".control.exit") as HTMLDivElement,
  generator: document.querySelector(".control.generator") as HTMLDivElement,
  settings: document.querySelector(".control.settings") as HTMLDivElement
};

export const dialogs = {
  generator: {
    self: document.querySelector("dialog.generator") as HTMLDialogElement,
    close: document.querySelector("dialog.generator .close") as HTMLDivElement,
    words: document.querySelector("dialog.generator input[name='words']") as HTMLInputElement,
    phoneNumbers: document.querySelector("dialog.generator input[name='phone-numbers']") as HTMLInputElement,
    phoneNumbersNoCode: document.querySelector("dialog.generator input[name='phone-numbers-no-code']") as HTMLInputElement,
    textarea: document.querySelector("dialog.generator textarea") as HTMLTextAreaElement,
    button: document.querySelector("dialog.generator button") as HTMLButtonElement
  },
  settings: {
    self: document.querySelector("dialog.settings") as HTMLDialogElement,
    form: document.querySelector("dialog.settings form") as HTMLFormElement,
    close: document.querySelector("dialog.settings .close") as HTMLDivElement,
    loadFont: document.querySelector("dialog.settings .load-font") as HTMLButtonElement,
    inputs: getSettingInputs()
  },
  error: {
    self: document.querySelector("dialog.error") as HTMLDialogElement,
    close: document.querySelector("dialog.error .close") as HTMLDivElement,
    message: document.querySelector("dialog.error .message") as HTMLParagraphElement
  }
};

export const quantities = document.querySelectorAll(".quantity") as NodeListOf<HTMLDivElement>;

function getSettingInputs() {
  let map = new Map<string, HTMLInputElement>();
  let elements = document.querySelectorAll("dialog.settings input");

  for (let element of elements) {
    map.set(element.getAttribute("name"), element as HTMLInputElement);
  }

  return map;
}

export const customFonts = document.querySelectorAll(".custom-font") as NodeListOf<HTMLElement>;
