const fadeOutDuration = 250;

export function show(element: HTMLElement) {
  element.classList.remove("hidden");
  element.classList.remove("hidden-progressively");
}

export function hide(element: HTMLElement) {
  element.classList.add("hidden");
}

export async function fadeOut(element: HTMLElement) {
  element.classList.add("fade-out");

  setTimeout(() => {
    element.classList.add("hidden");
    element.classList.remove("fade-out");
  }, fadeOutDuration);

  return new Promise((resolve) => setTimeout(resolve, fadeOutDuration));
}

export function enable(element: HTMLElement) {
  element.dataset.disabled = "false";
}

export function disable(element: HTMLElement) {
  element.dataset.disabled = "true";
}
