import { next } from "./extensions";
import * as Settings from "./settings";
import * as Top from "../data/top.json";

export function word() {
  Settings.ensureLoaded();
  let minLength = Settings.instance.get("min-word-length") as number;

  let word: string;
  while (!word || word.length < minLength) {
    let index = next(0, Top.length);
    word = Top[index];
  }

  return word;
}

export function words(amount: number) {
  let output = [];

  for (let i = 0; i < amount; i++) output.push(word());

  return output;
}

export function phoneNumber(diallingCode: boolean) {
  let digits = phoneNumberDigits(9);

  let raw = digits.join("").match(/.{1,3}/g);
  let joined = raw.join(" ");

  if (diallingCode) joined = `+420 ${joined}`;

  return joined;
}

export function phoneNumberDigits(amount: number) {
  let output = [];
  let last: number;

  let doublingIndex = next(0, amount);

  for (let i = 0; i < amount; i++) {
    last = i == doublingIndex ? last || 0 : next(0, 9);

    output.push(last);
  }

  return output;
}

export function phoneNumbers(diallingCode: boolean, amount: number) {
  let output = [];

  for (let i = 0; i < amount; i++) output.push(phoneNumber(diallingCode));

  return output;
}
