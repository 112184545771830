import * as Dom from "./dom";
import * as Effects from "./effects";
import * as Settings from "./settings";

export function load() {
  resetInit();
  addInit();
}

export function resetInit() {
  Dom.init.textarea.value = "";
}

export function addInit() {
  resetInit();
  Effects.show(Dom.init.self);
}

export function removeInit() {
  Effects.hide(Dom.init.self);
}

export async function fadeOutInit() {
  return Effects.fadeOut(Dom.init.self);
}

export function addLimbo() {
  Effects.show(Dom.limbo.img);
  Effects.show(Dom.limbo.self);
}

export function setLimboHeading(text: string) {
  Dom.limbo.heading.textContent = text;
}

export function removeLimbo() {
  Effects.hide(Dom.limbo.self);
}

export function removeLimboImage() {
  Effects.hide(Dom.limbo.img);
}

export async function fadeOutLimbo() {
  return Effects.fadeOut(Dom.limbo.self);
}

export function addScene() {
  Effects.show(Dom.scene.self);
}

export function removeScene() {
  Effects.hide(Dom.scene.self);
}

export function addProgress() {
  Effects.show(Dom.progress);
}

export function removeProgress() {
  Effects.hide(Dom.progress);
}

export function addGenerator() {
  Dom.dialogs.generator.textarea.value = "";
  Dom.dialogs.generator.self.showModal();
}

export function removeGenerator() {
  Dom.dialogs.generator.self.close();
}

export function addSettings() {
  Settings.ensureLoaded();
  fillSettings();

  Dom.dialogs.settings.self.showModal();
}

export function removeSettings() {
  Dom.dialogs.settings.self.close();
}

export function fillSettings() {
  let settings = Settings.instance;

  for (let [key, value] of settings) {
    let element = Dom.dialogs.settings.inputs.get(key);
    if (!element) continue;

    let input = element as HTMLInputElement;
    let type = element.getAttribute("type");

    if (type == "text") input.value = value as string;
    else if (type == "number") input.valueAsNumber = value as number;
  }
}

export function addError(message: string) {
  Dom.dialogs.error.message.innerHTML = message;
  Dom.dialogs.error.self.showModal();
}

export function removeError() {
  Dom.dialogs.error.self.close();
}
