import * as Dom from "./dom";
import * as FontService from "./services/font";
import * as Settings from "./settings";
import * as Display from "./display";

let element: HTMLLinkElement = null;
let monoWidth: number;
let serifWidth: number;
let sansWidth: number;
let container: HTMLSpanElement;

function init() {
  container = document.createElement("span");
  container.innerHTML = Array(100).join("wi");
  container.style.cssText = ["position:absolute", "width:auto", "font-size:128px", "left:-99999px"].join(" !important;");

  precomputeWidths();
}

function precomputeWidths() {
  monoWidth = getWidth("monospace");
  serifWidth = getWidth("serif");
  sansWidth = getWidth("sans-serif");
}

function getWidth(fontFamily: string) {
  container.style.fontFamily = fontFamily;

  document.body.appendChild(container);
  let width = container.clientWidth;
  document.body.removeChild(container);

  return width;
}

export function isAvailable(fontFamily: string) {
  return monoWidth !== getWidth(fontFamily + ",monospace") || sansWidth !== getWidth(fontFamily + ",sans-serif") || serifWidth !== getWidth(fontFamily + ",serif");
}

export function install(name: string) {
  uninstall();

  let url = `https://fonts.googleapis.com/css?family=${name}`;

  let link = document.createElement("link") as HTMLLinkElement;
  link.setAttribute("rel", "stylesheet");
  link.href = url;

  document.head.append(link);
  element = link;
}

export function uninstall() {
  if (element != null) {
    element.remove();
    element = null;
  }
}

export function set(fontFamily: string) {
  for (let element of Dom.customFonts) {
    element.style.fontFamily = fontFamily;
  }
}

export async function loadAndSet() {
  Settings.ensureLoaded();
  let fontFamily = Settings.instance.get("font-family") as string;

  let success = await process(fontFamily);
  if (!success) return;

  set(fontFamily);
}

export async function existsRemotely(fontFamily: string) {
  return FontService.exists(fontFamily);
}

export async function process(fontFamily: string) {
  let isAvailableResult: boolean;
  let existsRemotelyResult: boolean;

  isAvailableResult = isAvailable(fontFamily);
  if (!isAvailableResult) {
    existsRemotelyResult = await existsRemotely(fontFamily);
    if (!existsRemotelyResult) {
      Display.addError(`Font neexistuje lokálně ani online.<br />Katalog: <a href="https://fonts.google.com" target="_blank">https://fonts.google.com</a>`);
      return false;
    }
  }

  if (!isAvailableResult && existsRemotelyResult) {
    console.log("Installing font", fontFamily);
    install(fontFamily);
  }

  return true;
}

init();
