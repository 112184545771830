import * as Dom from "./dom";

export function registerHandlers() {
  for (let quantity of Dom.quantities) registerHandler(quantity);
}

export function registerHandler(element: HTMLDivElement) {
  let input = element.querySelector("input");
  let buttonUp = element.querySelector(".quantity-up") as HTMLDivElement;
  let buttonDown = element.querySelector(".quantity-down") as HTMLDivElement;
  let min = input.hasAttribute("min") ? Number(input.getAttribute("min")) : null;
  let max = input.hasAttribute("max") ? Number(input.getAttribute("max")) : null;

  input.onkeydown = async (event: KeyboardEvent) => {
    if (event.getModifierState("Meta") || event.getModifierState("Control") || event.getModifierState("Alt")) {
      return;
    }

    if (event.key.length !== 1 || event.key === "\x00") {
      return;
    }

    if (event.key < "0" || event.key > "9") {
      event.preventDefault();
    }
  };

  buttonUp.onclick = async () => {
    let current = input.valueAsNumber;
    if (!current || isNaN(current)) current = 0;

    let updated = ++current;
    if (max != null && updated > max) return;
    input.valueAsNumber = updated;

    triggerEvents(input);
  };

  buttonDown.onclick = async () => {
    let current = input.valueAsNumber;
    if (!current || isNaN(current)) current = 0;

    let updated = --current;
    if (min != null && updated < min) return;
    input.valueAsNumber = updated;

    triggerEvents(input);
  };
}

function triggerEvents(input: HTMLInputElement) {
  input.dispatchEvent(
    new Event("input", {
      bubbles: true,
      cancelable: true
    })
  );

  input.dispatchEvent(
    new Event("change", {
      bubbles: true,
      cancelable: true
    })
  );
}
