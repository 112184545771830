import * as Dom from "./dom";
import * as Effects from "./effects";

let controls = Array.from(Object.values(Dom.controls)).filter((x) => x.parentElement.classList.contains("center"));

export function enableAll() {
  controls.forEach((control) => {
    Effects.enable(control);
  });
}

export function disableAll() {
  controls.forEach((control) => {
    Effects.disable(control);
  });
}

export function disablePlayButton() {
  Effects.disable(Dom.controls.play);
}

export function enablePlayButton() {
  Effects.enable(Dom.controls.play);
}

export function handleGameStart() {
  disablePlayButton();
  Effects.enable(Dom.controls.exit);
}

export function disableExitButton() {
  Effects.disable(Dom.controls.exit);
}

export function handleGameEnd() {
  disableAll();

  Effects.enable(Dom.controls.restart);
  Effects.enable(Dom.controls.exit);
}
