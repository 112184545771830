import * as Progress from "./progress";
import * as Dom from "./dom";
import * as Display from "./display";
import * as Events from "./events";
import * as Quantity from "./quantity";
import * as Generate from "./generate";
import * as Settings from "./settings";
import * as Font from "./font";
import * as Controls from "./controls";
import * as Effects from "./effects";
import * as Game from "./game";

declare global {
  interface Window {
    Progress: unknown;
    Dom: unknown;
    Display: unknown;
    Events: unknown;
    Quantity: unknown;
    Generate: unknown;
    Settings: unknown;
    Font: unknown;
    Controls: unknown;
    Effects: unknown;
    Game: unknown;
  }
}

async function main() {
  Quantity.registerHandlers();
  registerGlobals();
  Display.load();
  Font.loadAndSet();
  Settings.apply();
}

function registerGlobals() {
  window.Progress = Progress;
  window.Dom = Dom;
  window.Display = Display;
  window.Events = Events;
  window.Quantity = Quantity;
  window.Generate = Generate;
  window.Settings = Settings;
  window.Font = Font;
  window.Controls = Controls;
  window.Effects = Effects;
  window.Game = Game;
}

main();
